<template>
  <div>
    <div class="class1">
      <div class="flexRowCenterBetween class2">
        <img src="@/assets/logo.png" class="class3" />
        <el-button type="success" @click="onSubmit">APPLY NOW</el-button>
      </div>

      <div class="flexRowCenterCenter">
        <div class="class6">
          <div style="color: #75ffb3">
            Find remote jobs and earn extra money with Indeed, the #1 job site
            that makes working easy
          </div>
          <div style="color: #ffffff">-No Ads, Scams, or Junk!</div>
        </div>

        <div class="class4">
          <el-form
            :model="form"
            label-width="auto"
            label-position="top"
            ref="formRef"
            :rules="rules"
          >
            <el-form-item label="Apply to Land Your Perfect Remote Job Today!">
            </el-form-item>

            <el-form-item label="Name" prop="name">
              <el-input v-model="form.name" placeholder="Your name"></el-input>
            </el-form-item>

            <el-form-item label="Gender" prop="gender">
              <el-select v-model="form.gender" placeholder="Gender">
                <el-option label="Male" value="Male"></el-option>
                <el-option label="Female" value="Female"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Age" prop="age">
              <el-input
                v-model="form.age"
                placeholder="Age"
                type="number"
              ></el-input>
            </el-form-item>

            <el-form-item label="Phone number with WhatsApp" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="Enter correct WhatsApp number"
              >
                <template v-slot:prefix>
                  <div class="flexRowCenterCenter">
                    <img
                      src="@/assets/helan.png"
                      style="width: 3rem; height: auto"
                    />
                    <div style="color: #000; padding: 0 1rem 0 1rem">+31</div>
                  </div>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item label="Email" prop="email">
              <el-input v-model="form.email" placeholder="Email"></el-input>
            </el-form-item>

            <el-form-item label="" prop="meetAgeRequirements">
              <el-checkbox-group v-model="form.meetAgeRequirements">
                <el-checkbox name="type" value="true"
                  >Yes, I am over 24.</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <el-form-item label="">
              <div class="class5">
                Before submitting, please ensure that you are a Dutch
                citizen.Because this online job is only available to Dutch
                citizens.
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="success" @click="onSubmit">APPLY NOW</el-button>
              <el-button type="info" @click="onReset">RESET</el-button>
            </el-form-item>

            <el-form-item label="">
              <div>
                Recruiters will contact job applicants via WhatsApp. Please
                provide your WhatsApp phone number for follow-up during the next
                steps of the recruitment process.
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="class7">
      <div
        class="flexColumnCenterCenter"
        style="
          color: #003248;
          font-size: 2.4rem;
          font-weight: bold;
          padding-top: 6rem;
        "
      >
        What Our Members Are Saying
      </div>

      <div style="margin-top: 3rem">
        <el-carousel :interval="4000" type="card" height="400px">
          <el-carousel-item v-for="item in carouselList" :key="item">
            <div>
              <div class="flexRowCenter" style="padding: 2rem">
                <img :src="item.headImg" />

                <div style="margin-left: 1rem">
                  <div style="font-weight: bold; font-size: 1.5rem">
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.job }}
                  </div>
                </div>
              </div>

              <div style="padding: 0 2rem 0 2rem">
                <img
                  src="@/assets/score_start.png"
                  style="padding: 0 0.3rem 0 0.3rem"
                />
                <img
                  src="@/assets/score_start.png"
                  style="padding: 0 0.3rem 0 0.3rem"
                />
                <img
                  src="@/assets/score_start.png"
                  style="padding: 0 0.3rem 0 0.3rem"
                />
                <img
                  src="@/assets/score_start.png"
                  style="padding: 0 0.3rem 0 0.3rem"
                />
                <img
                  src="@/assets/score_start.png"
                  style="padding: 0 0.3rem 0 0.3rem"
                />
              </div>

              <div style="font-size: 1.5rem; padding: 2rem">
                {{ item.content }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="flexRowCenterCenter" style="padding: 3rem 0 6rem 0">
        <el-button type="success" style="width: 28rem; height: 2.8rem"
          >Make Money From Home Now！</el-button
        >
      </div>
    </div>

    <div>
      <div
        class="flexColumnCenterCenter"
        style="
          color: #003248;
          font-size: 2.4rem;
          font-weight: bold;
          padding-top: 6rem;
        "
      >
        Discover Indeed Jobs To Move You Into A Rewarding Career!
      </div>

      <div
        class="flexColumnCenterCenter"
        style="color: #003248; font-size: 1.4rem; padding-top: 1rem"
      >
        Apply for job opportunities that offer part-time and full-time
        flexibility. Play a key role in our journey to business excellence.
      </div>

      <div class="flexRowCenterCenter" style="padding: 6rem">
        <div
          class="class9"
          @mouseenter="imgOneShow = false"
          @mouseleave="imgOneShow = true"
        >
          <el-image
            class="class8"
            :src="require('@/assets/discover1-63c5Km9w.webp')"
            fit="fill"
            v-if="imgOneShow"
          ></el-image>

          <div v-if="!imgOneShow" class="class12">
            <div class="class10">Remote Online Promoter</div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Earn extra wages every day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Flexible hours: minimum 30 mins/day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Work anytime, anywhere.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Use any smart device.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>0 skill needed.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Must be between 24-60 years old</div>
            </div>

            <div class="flexRowCenterCenter">
              <el-button
                type="success"
                style="margin-top: 3rem; width: 20rem; height: 3rem"
                @click="onSubmit"
              >
                Apply Now
              </el-button>
            </div>
          </div>
        </div>
        <div
          class="class9"
          @mouseenter="imgTwoShow = false"
          @mouseleave="imgTwoShow = true"
        >
          <el-image
            class="class8"
            :src="require('@/assets/discover2-CqakWN4R.webp')"
            fit="fill"
            v-if="imgTwoShow"
          ></el-image>

          <div v-if="!imgTwoShow" class="class12">
            <div class="class10">Remote Sales</div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Earn extra wages every day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Flexible hours: minimum 50 mins/day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Work anytime, anywhere.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Use any smart device.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Must be between 24-60 years old</div>
            </div>
            <div class="flexRowCenterCenter">
              <el-button
                type="success"
                style="margin-top: 3rem; width: 20rem; height: 3rem"
                @click="onSubmit"
              >
                Apply Now
              </el-button>
            </div>
          </div>
        </div>
        <div
          class="class9"
          @mouseenter="imgThreeShow = false"
          @mouseleave="imgThreeShow = true"
        >
          <el-image
            class="class8"
            :src="require('@/assets/discover3-mSOkZsUh.webp')"
            fit="fill"
            v-if="imgThreeShow"
          ></el-image>

          <div v-if="!imgThreeShow" class="class12">
            <div class="class10">Remote Customer Service</div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Earn extra wages every day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Flexible hours: minimum 60 mins/day.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Work anytime, anywhere.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Use any smart device.</div>
            </div>
            <div class="flexRowCenter class11">
              <img src="@/assets/circle.svg" />
              <div>Must be between 24-60 years old</div>
            </div>
            <div class="flexRowCenterCenter">
              <el-button
                type="success"
                style="margin-top: 3rem; width: 20rem; height: 3rem"
                @click="onSubmit"
              >
                Apply Now
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        class="flexColumnCenterCenter"
        style="
          color: #003248;
          font-size: 2.4rem;
          font-weight: bold;
          padding-top: 3rem;
        "
      >
        How FunJobsBuilder is Different？
      </div>

      <div
        class="flexColumnCenterCenter"
        style="color: #003248; font-size: 1.4rem; padding-top: 1rem"
      >
        Featuring 104,861 Online Jobs From 4,692 Companies
      </div>

      <div class="flexRowCenterCenter" style="padding: 3rem 6rem 0 6rem">
        <div>
          <div class="flexRow" style="width: 50rem; margin-top: 3rem">
            <el-image
              :src="require('@/assets/legit.png')"
              fit="fill"
              style="
                width: 1.5rem;
                height: 1.3rem;
                background-color: #dcfefb;
                padding: 1rem;
                border-radius: 0.5rem;
              "
            ></el-image>

            <div style="padding-left: 1rem; color: #385576">
              <div style="font-size: 2rem; font-weight: bold">
                Legit Jobs Only
              </div>
              <div style="margin-top: 1rem">
                - Free from Ads, Scams, and Junk
              </div>
              <div style="margin-top: 1rem">
                Find real job opportunities. Our team spends more than 10 hours
                each day ensuring all remote jobs are verified and scam-free.
              </div>
            </div>
          </div>

          <div class="flexRow" style="width: 50rem; margin-top: 3rem">
            <el-image
              :src="require('@/assets/unlock.png')"
              fit="fill"
              style="
                width: 1.5rem;
                height: 1.3rem;
                background-color: #dcfefb;
                padding: 1rem;
                border-radius: 0.5rem;
              "
            ></el-image>

            <div style="padding-left: 1rem; color: #385576">
              <div style="font-size: 2rem; font-weight: bold">
                Unlock Full Access
              </div>
              <div style="margin-top: 1rem">
                – Unlimited Job Search Resources
              </div>
              <div style="margin-top: 1rem">
                Enjoy full access to all our features: unlimited job listings,
                valuable articles, skills tests, and informative webinars to
                support your remote job search
              </div>
            </div>
          </div>

          <div class="flexRow" style="width: 50rem; margin-top: 3rem">
            <el-image
              :src="require('@/assets/efficient.png')"
              fit="fill"
              style="
                width: 1.5rem;
                height: 1.3rem;
                background-color: #dcfefb;
                padding: 1rem;
                border-radius: 0.5rem;
              "
            ></el-image>

            <div style="padding-left: 1rem; color: #385576">
              <div style="font-size: 2rem; font-weight: bold">
                Efficient Job Hunting
              </div>
              <div style="margin-top: 1rem">
                – WhatsApp to Application Directly
              </div>
              <div style="margin-top: 1rem">
                Optimize your job search by messaging directly from WhatsApp to
                job applications, avoiding the use of multiple job boards.
              </div>
            </div>
          </div>
        </div>

        <el-image
          :src="require('@/assets/different-XN7E96UD.webp')"
          fit="fill"
          style="width: 32.1rem"
        ></el-image>
      </div>

      <div class="flexRowCenterCenter" style="padding: 3rem 0 6rem 0">
        <el-button type="success" style="width: 28rem; height: 2.8rem"
          >Make Money From Home Now！</el-button
        >
      </div>
    </div>

    <div style="background-color: #edf8f7" class="flexColumnCenterCenter">
      <div style="font-size: 3rem; font-weight: bold; margin-top: 5rem">
        Over 8 Million Job Seekers
      </div>

      <div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem">
        Have Used FunJobsBuilder to Find a Better Way to Make Money From Home
      </div>

      <div class="flexRowCenterCenter" style="margin-top: 3rem">
        <div
          class="flexColumnCenterCenter"
          style="
            background-color: #fff;
            padding: 3rem 1rem 3rem 1rem;
            border-radius: 1rem;
            width: 15rem;
            height: 20rem;
            margin: 0 1rem 0 1rem;
          "
        >
          <el-image
            :src="require('@/assets/seekers1-CDDczeKG.png')"
            fit="fill"
            style="width: 8rem; height: 8rem; border-radius: 50%"
          ></el-image>
          <div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem">
            Legit At Home Jobs
          </div>
          <div
            style="
              font-size: 1rem;
              font-weight: bold;
              margin-top: 1rem;
              color: #999;
            "
          >
            Provide a range of legitimate at-home job opportunities that allow
            you to work from the comfort of your home.
          </div>
        </div>

        <div
          class="flexColumnCenterCenter"
          style="
            background-color: #fff;
            padding: 3rem 1rem 3rem 1rem;
            border-radius: 1rem;
            width: 15rem;
            height: 20rem;
            margin: 0 1rem 0 1rem;
          "
        >
          <el-image
            :src="require('@/assets/seekers2-fQqOLGA7.png')"
            fit="fill"
            style="width: 8rem; height: 8rem; border-radius: 50%"
          ></el-image>
          <div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem">
            Competitive Salary
          </div>
          <div
            style="
              font-size: 1rem;
              font-weight: bold;
              margin-top: 1rem;
              color: #999;
            "
          >
            Performance-based pay guarantees extra pay every day
          </div>
        </div>

        <div
          class="flexColumnCenterCenter"
          style="
            background-color: #fff;
            padding: 3rem 1rem 3rem 1rem;
            border-radius: 1rem;
            width: 15rem;
            height: 20rem;
            margin: 0 1rem 0 1rem;
          "
        >
          <el-image
            :src="require('@/assets/seekers3-BX76o7RI.png')"
            fit="fill"
            style="width: 8rem; height: 8rem; border-radius: 50%"
          ></el-image>
          <div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem">
            Flexible Working Hours
          </div>
          <div
            style="
              font-size: 1rem;
              font-weight: bold;
              margin-top: 1rem;
              color: #999;
            "
          >
            Enjoy the freedom of flexible working hours, with a minimum
            requirement of just 30 minutes per day.
          </div>
        </div>

        <div
          class="flexColumnCenterCenter"
          style="
            background-color: #fff;
            padding: 3rem 1rem 3rem 1rem;
            border-radius: 1rem;
            width: 15rem;
            height: 20rem;
            margin: 0 1rem 0 1rem;
          "
        >
          <el-image
            :src="require('@/assets/seekers4-tH8-8NhH.png')"
            fit="fill"
            style="width: 8rem; height: 8rem; border-radius: 50%"
          ></el-image>
          <div style="font-size: 1.5rem; font-weight: bold; margin-top: 1rem">
            No Experience Needed
          </div>
          <div
            style="
              font-size: 1rem;
              font-weight: bold;
              margin-top: 1rem;
              color: #999;
            "
          >
            Comprehensive training and support provided. Just bring your
            willingness to learn and a reliable internet connection.
          </div>
        </div>
      </div>

      <div class="flexRowCenterCenter" style="padding: 3rem 0 6rem 0">
        <el-button type="success" style="width: 28rem; height: 2.8rem"
          >Find Indeed Jobs Near Me!</el-button
        >
      </div>
    </div>

    <div class="flexColumnCenterCenter">
      <div style="font-size: 3rem; font-weight: bold; margin-top: 8rem">
        FAQs
      </div>

      <el-collapse style="width: 100rem; margin-top: 2rem">
        <el-collapse-item name="1" style="padding: 2rem 0 2rem 0">
          <template v-slot:title>
            <div style="font-size: 1.5rem">
              What Types of Tasks Are Available?
            </div>
          </template>
          <div style="font-size: 1rem">
            We offer a diverse range of tasks such as market research, online
            shopping, and surveys, Our recruiters will conduct an online
            interview to provide tasks that suit you before starting!
          </div>
        </el-collapse-item>
        <el-collapse-item name="2" style="padding: 2rem 0 2rem 0">
          <template v-slot:title>
            <div style="font-size: 1.5rem">Can I choose my work hours？</div>
          </template>
          <div style="font-size: 1rem">
            Absolutely! You have the flexibility to choose your work hours,
            making it easy to fit tasks into your schedule.
          </div>
        </el-collapse-item>
        <el-collapse-item name="3" style="padding: 2rem 0 2rem 0">
          <template v-slot:title>
            <div style="font-size: 1.5rem">How will I be paid?</div>
          </template>
          <div style="font-size: 1rem">
            Payments are processed quickly and securely through your preferred
            method, such as direct deposit or PayPal.
          </div>
        </el-collapse-item>
        <el-collapse-item name="4" style="padding: 2rem 0 2rem 0">
          <template v-slot:title>
            <div style="font-size: 1.5rem">
              Are there any minimum requirements for the tasks?
            </div>
          </template>
          <div style="font-size: 1rem">
            Requirements may vary depending on the task. Generally, you'll need
            a computer or mobile device with internet access and a basic
            understanding of English. Specific tasks might require additional
            skills or software.
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div
      style="background-color: #003248; margin-top: 8rem"
      class="flexRowCenterCenter"
    >
      <el-image
        :src="require('@/assets/earn1.png')"
        fit="cover"
        style="width: 30rem; margin-top: 4rem"
      ></el-image>

      <div style="width: 50rem">
        <div style="font-size: 3rem; color: #fff; font-weight: bold">
          Earn tons of extra pay every week in your free time!
        </div>
        <div style="font-size: 2rem; color: #fff; margin-top: 1rem">
          Seize the jobs opportunity now！
        </div>
        <el-button
          type="success"
          style="margin-top: 2rem; width: 10rem"
          @click="onSubmit"
        >
          Apply Now
        </el-button>
      </div>
    </div>

    <div style="background-color: #000; color: #fff">
      <div
        style="padding: 5rem 0 5rem 0; font-size: 1.5rem"
        class="flexRowCenterCenter"
      >
        <el-image
          :src="require('@/assets/logo.png')"
          fit="cover"
          style="width: 10rem"
        ></el-image>
        <!-- <div style="padding: 0 1rem 0 2rem">Contact us via whatsapp:</div>
        <div style="padding: 0 1rem 0 0">+1（646）750-0503</div>
        <div style="padding: 0 1rem 0 1rem">Terms and Conditions</div> -->
      </div>

      <el-divider></el-divider>

      <div
        class="flexRowCenterCenter"
        style="font-size: 1rem; padding: 2.5rem 0 2.5rem 0"
      >
        Copyrights © 2024 www.funjobsbuilder.com Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from "vue";
import type { FormRules, FormInstance } from "element-plus";
import { ElMessageBox, ElLoading } from "element-plus";
import request from "@/utils/request";

const form = ref({
  name: "",
  gender: "",
  age: "",
  phone: "",
  email: "",
  meetAgeRequirements: [],
});

const carouselList = ref([
  {
    headImg: require("@/assets/user01.svg"),
    name: "Noah W",
    job: "Placed Candidate(Part time)",
    content:
      "The career development resources offered are top-notch. I improved my resume and interviewing skills, which helped me land a great job.",
  },
  {
    headImg: require("@/assets/user02.svg"),
    name: "Lucas P",
    job: "Placed Candidate(Full Time)",
    content:
      "The site’s personalized recommendations were spot on. I was matched with a job that perfectly aligns with my skills and interests.",
  },
  {
    headImg: require("@/assets/user03.svg"),
    name: "Grace C",
    job: "Placed Candidate(Full Time)",
    score: "5",
    content:
      "I was able to connect with reputable employers and found a high-paying part-time job. The entire experience was positive and stress-free.",
  },
  {
    headImg: require("@/assets/user04.svg"),
    name: "Lily S",
    job: "Placed Candidate(Full Time)",
    content:
      "This website is a lifesaver for busy moms like me. I found a part-time job that allows me to work from home and spend more time with my family.",
  },
  {
    headImg: require("@/assets/user05.svg"),
    name: "Amelia D",
    job: "Placed Candidate(Full Time)",
    content:
      "The part-time job I found through this website has been perfect for balancing my studies and work. I highly recommend it to anyone looking for flexible job options.",
  },
]);

const imgOneShow = ref(true);
const imgTwoShow = ref(true);
const imgThreeShow = ref(true);

interface RuleForm {
  name: string;
  gender: string;
  age: number;
  phone: string;
  email: string;
  meetAgeRequirements: Array<boolean>;
}

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: "Please enter your name", trigger: "blur" },
  ],
  gender: [{ required: true, message: "Please enter gender", trigger: "blur" }],
  age: [{ required: true, message: "Please enter age", trigger: "blur" }],
  phone: [
    {
      required: true,
      message: "Please enter your phone number",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: "Please enter your email address",
      trigger: "blur",
    },
  ],
  meetAgeRequirements: [
    { required: true, message: "Please check the agreement", trigger: "blur" },
  ],
});

const formRef = ref<FormInstance>();

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const onSubmit = async () => {
  scrollToTop();
  if (!formRef.value) return;
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  await formRef.value.validate((valid, fields) => {
    if (valid) {
      request.post("/system/portal/userAdd", form.value).then((res) => {
        loading.close();
        ElMessageBox.alert("Submitted successfully", "Success", {
          confirmButtonText: "OK",
          callback: () => {
            onReset();
          },
        });
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};

const onReset = () => {
  if (!formRef.value) return;
  formRef.value.resetFields();
};
</script>

<style scoped>
.el-carousel__item {
  background-color: #fff;
  border-radius: 1rem;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowCenterBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexRowCenterAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flexRowCenterCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexColumnCenterCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.class1 {
  background-image: url("@/assets/header_web_bg-DHunKE72.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vm;
  height: auto;
}

.class2 {
  padding: 1rem 5rem 0 5rem;
}

.class3 {
  width: 11.75rem;
}

.class4 {
  background-color: #fff;
  width: 30rem;
  border-radius: 1rem;
  padding: 2rem;
}

.class5 {
  border: 2px solid #007be5;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #e6f7ff;
  color: #007be5;
  font-size: 1rem;
  font-weight: bold;
}

.class6 {
  width: 30rem;
  font-size: 3rem;
  font-weight: bold;
  padding-right: 24rem;
}

.class7 {
  background-color: #edf8f7;
  margin-top: 1rem;
}

.class8 {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.class9 {
  width: 25rem;
  height: 34rem;
  margin: 1rem;
}

.class10 {
  font-size: 2rem;
  padding: 1rem 0 0 1rem;
  font-weight: bold;
}

.class11 {
  padding: 1.5rem 0 0 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.class11 div {
  padding-left: 1rem;
}

.class12 {
  background-color: #003248;
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 1rem;
}
</style>
