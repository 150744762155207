import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

// 创建一个 Axios 实例
const instance = axios.create({
  baseURL: "https://www.timegoodjob.top/prod-api", // 替换为你的 API 基础 URL
  timeout: 10000, // 请求超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 在发送请求之前做些什么，比如添加 token
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 封装请求方法
const request = {
  get: <T>(url: string, config?: Partial<AxiosRequestConfig>): Promise<T> => {
    return instance.get<T>(url, config).then((response) => response.data); // 返回响应数据
  },
  post: <T>(
    url: string,
    data?: any,
    config?: Partial<AxiosRequestConfig>
  ): Promise<T> => {
    return instance
      .post<T>(url, data, config)
      .then((response) => response.data);
  },
  put: <T>(
    url: string,
    data?: any,
    config?: Partial<AxiosRequestConfig>
  ): Promise<T> => {
    return instance.put<T>(url, data, config).then((response) => response.data);
  },
  delete: <T>(
    url: string,
    config?: Partial<AxiosRequestConfig>
  ): Promise<T> => {
    return instance.delete<T>(url, config).then((response) => response.data);
  },
};

export default request;
