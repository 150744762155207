import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/helan.png'
import _imports_2 from '@/assets/score_start.png'
import _imports_3 from '@/assets/circle.svg'


const _hoisted_1 = { class: "class1" }
const _hoisted_2 = { class: "flexRowCenterBetween class2" }
const _hoisted_3 = { class: "flexRowCenterCenter" }
const _hoisted_4 = { class: "class4" }
const _hoisted_5 = { class: "class7" }
const _hoisted_6 = { style: {"margin-top":"3rem"} }
const _hoisted_7 = {
  class: "flexRowCenter",
  style: {"padding":"2rem"}
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { style: {"margin-left":"1rem"} }
const _hoisted_10 = { style: {"font-weight":"bold","font-size":"1.5rem"} }
const _hoisted_11 = { style: {"font-size":"1.5rem","padding":"2rem"} }
const _hoisted_12 = {
  class: "flexRowCenterCenter",
  style: {"padding":"3rem 0 6rem 0"}
}
const _hoisted_13 = {
  class: "flexRowCenterCenter",
  style: {"padding":"6rem"}
}
const _hoisted_14 = {
  key: 1,
  class: "class12"
}
const _hoisted_15 = { class: "flexRowCenterCenter" }
const _hoisted_16 = {
  key: 1,
  class: "class12"
}
const _hoisted_17 = { class: "flexRowCenterCenter" }
const _hoisted_18 = {
  key: 1,
  class: "class12"
}
const _hoisted_19 = { class: "flexRowCenterCenter" }
const _hoisted_20 = {
  class: "flexRowCenterCenter",
  style: {"padding":"3rem 6rem 0 6rem"}
}
const _hoisted_21 = {
  class: "flexRow",
  style: {"width":"50rem","margin-top":"3rem"}
}
const _hoisted_22 = {
  class: "flexRow",
  style: {"width":"50rem","margin-top":"3rem"}
}
const _hoisted_23 = {
  class: "flexRow",
  style: {"width":"50rem","margin-top":"3rem"}
}
const _hoisted_24 = {
  class: "flexRowCenterCenter",
  style: {"padding":"3rem 0 6rem 0"}
}
const _hoisted_25 = {
  style: {"background-color":"#edf8f7"},
  class: "flexColumnCenterCenter"
}
const _hoisted_26 = {
  class: "flexRowCenterCenter",
  style: {"margin-top":"3rem"}
}
const _hoisted_27 = {
  class: "flexColumnCenterCenter",
  style: {"background-color":"#fff","padding":"3rem 1rem 3rem 1rem","border-radius":"1rem","width":"15rem","height":"20rem","margin":"0 1rem 0 1rem"}
}
const _hoisted_28 = {
  class: "flexColumnCenterCenter",
  style: {"background-color":"#fff","padding":"3rem 1rem 3rem 1rem","border-radius":"1rem","width":"15rem","height":"20rem","margin":"0 1rem 0 1rem"}
}
const _hoisted_29 = {
  class: "flexColumnCenterCenter",
  style: {"background-color":"#fff","padding":"3rem 1rem 3rem 1rem","border-radius":"1rem","width":"15rem","height":"20rem","margin":"0 1rem 0 1rem"}
}
const _hoisted_30 = {
  class: "flexColumnCenterCenter",
  style: {"background-color":"#fff","padding":"3rem 1rem 3rem 1rem","border-radius":"1rem","width":"15rem","height":"20rem","margin":"0 1rem 0 1rem"}
}
const _hoisted_31 = {
  class: "flexRowCenterCenter",
  style: {"padding":"3rem 0 6rem 0"}
}
const _hoisted_32 = { class: "flexColumnCenterCenter" }
const _hoisted_33 = {
  style: {"background-color":"#003248","margin-top":"8rem"},
  class: "flexRowCenterCenter"
}
const _hoisted_34 = { style: {"width":"50rem"} }
const _hoisted_35 = { style: {"background-color":"#000","color":"#fff"} }
const _hoisted_36 = {
  style: {"padding":"5rem 0 5rem 0","font-size":"1.5rem"},
  class: "flexRowCenterCenter"
}

import { ref, reactive } from "vue";
import type { FormRules, FormInstance } from "element-plus";
import { ElMessageBox, ElLoading } from "element-plus";
import request from "@/utils/request";

interface RuleForm {
  name: string;
  gender: string;
  age: number;
  phone: string;
  email: string;
  meetAgeRequirements: Array<boolean>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const form = ref({
  name: "",
  gender: "",
  age: "",
  phone: "",
  email: "",
  meetAgeRequirements: [],
});

const carouselList = ref([
  {
    headImg: require("@/assets/user01.svg"),
    name: "Noah W",
    job: "Placed Candidate(Part time)",
    content:
      "The career development resources offered are top-notch. I improved my resume and interviewing skills, which helped me land a great job.",
  },
  {
    headImg: require("@/assets/user02.svg"),
    name: "Lucas P",
    job: "Placed Candidate(Full Time)",
    content:
      "The site’s personalized recommendations were spot on. I was matched with a job that perfectly aligns with my skills and interests.",
  },
  {
    headImg: require("@/assets/user03.svg"),
    name: "Grace C",
    job: "Placed Candidate(Full Time)",
    score: "5",
    content:
      "I was able to connect with reputable employers and found a high-paying part-time job. The entire experience was positive and stress-free.",
  },
  {
    headImg: require("@/assets/user04.svg"),
    name: "Lily S",
    job: "Placed Candidate(Full Time)",
    content:
      "This website is a lifesaver for busy moms like me. I found a part-time job that allows me to work from home and spend more time with my family.",
  },
  {
    headImg: require("@/assets/user05.svg"),
    name: "Amelia D",
    job: "Placed Candidate(Full Time)",
    content:
      "The part-time job I found through this website has been perfect for balancing my studies and work. I highly recommend it to anyone looking for flexible job options.",
  },
]);

const imgOneShow = ref(true);
const imgTwoShow = ref(true);
const imgThreeShow = ref(true);

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: "Please enter your name", trigger: "blur" },
  ],
  gender: [{ required: true, message: "Please enter gender", trigger: "blur" }],
  age: [{ required: true, message: "Please enter age", trigger: "blur" }],
  phone: [
    {
      required: true,
      message: "Please enter your phone number",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: "Please enter your email address",
      trigger: "blur",
    },
  ],
  meetAgeRequirements: [
    { required: true, message: "Please check the agreement", trigger: "blur" },
  ],
});

const formRef = ref<FormInstance>();

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const onSubmit = async () => {
  scrollToTop();
  if (!formRef.value) return;
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  await formRef.value.validate((valid, fields) => {
    if (valid) {
      request.post("/system/portal/userAdd", form.value).then((res) => {
        loading.close();
        ElMessageBox.alert("Submitted successfully", "Success", {
          confirmButtonText: "OK",
          callback: () => {
            onReset();
          },
        });
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};

const onReset = () => {
  if (!formRef.value) return;
  formRef.value.resetFields();
};

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[13] || (_cache[13] = _createElementVNode("img", {
          src: _imports_0,
          class: "class3"
        }, null, -1)),
        _createVNode(_component_el_button, {
          type: "success",
          onClick: onSubmit
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("APPLY NOW")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "class6" }, [
          _createElementVNode("div", { style: {"color":"#75ffb3"} }, " Find remote jobs and earn extra money with Indeed, the #1 job site that makes working easy "),
          _createElementVNode("div", { style: {"color":"#ffffff"} }, "-No Ads, Scams, or Junk!")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_form, {
            model: form.value,
            "label-width": "auto",
            "label-position": "top",
            ref_key: "formRef",
            ref: formRef,
            rules: rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Apply to Land Your Perfect Remote Job Today!" }),
              _createVNode(_component_el_form_item, {
                label: "Name",
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: form.value.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
                    placeholder: "Your name"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Gender",
                prop: "gender"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: form.value.gender,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.gender) = $event)),
                    placeholder: "Gender"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Male",
                        value: "Male"
                      }),
                      _createVNode(_component_el_option, {
                        label: "Female",
                        value: "Female"
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Age",
                prop: "age"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: form.value.age,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.age) = $event)),
                    placeholder: "Age",
                    type: "number"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Phone number with WhatsApp",
                prop: "phone"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: form.value.phone,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.phone) = $event)),
                    placeholder: "Enter correct WhatsApp number"
                  }, {
                    prefix: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("div", { class: "flexRowCenterCenter" }, [
                        _createElementVNode("img", {
                          src: _imports_1,
                          style: {"width":"3rem","height":"auto"}
                        }),
                        _createElementVNode("div", { style: {"color":"#000","padding":"0 1rem 0 1rem"} }, "+31")
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Email",
                prop: "email"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: form.value.email,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.email) = $event)),
                    placeholder: "Email"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "",
                prop: "meetAgeRequirements"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_checkbox_group, {
                    modelValue: form.value.meetAgeRequirements,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.meetAgeRequirements) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_checkbox, {
                        name: "type",
                        value: "true"
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("Yes, I am over 24.")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("div", { class: "class5" }, " Before submitting, please ensure that you are a Dutch citizen.Because this online job is only available to Dutch citizens. ", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "success",
                    onClick: onSubmit
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("APPLY NOW")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "info",
                    onClick: onReset
                  }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode("RESET")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "" }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createElementVNode("div", null, " Recruiters will contact job applicants via WhatsApp. Please provide your WhatsApp phone number for follow-up during the next steps of the recruitment process. ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[23] || (_cache[23] = _createElementVNode("div", {
        class: "flexColumnCenterCenter",
        style: {"color":"#003248","font-size":"2.4rem","font-weight":"bold","padding-top":"6rem"}
      }, " What Our Members Are Saying ", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_carousel, {
          interval: 4000,
          type: "card",
          height: "400px"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carouselList.value, (item) => {
              return (_openBlock(), _createBlock(_component_el_carousel_item, { key: item }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("img", {
                        src: item.headImg
                      }, null, 8, _hoisted_8),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1),
                        _createElementVNode("div", null, _toDisplayString(item.job), 1)
                      ])
                    ]),
                    _cache[21] || (_cache[21] = _createElementVNode("div", { style: {"padding":"0 2rem 0 2rem"} }, [
                      _createElementVNode("img", {
                        src: _imports_2,
                        style: {"padding":"0 0.3rem 0 0.3rem"}
                      }),
                      _createElementVNode("img", {
                        src: _imports_2,
                        style: {"padding":"0 0.3rem 0 0.3rem"}
                      }),
                      _createElementVNode("img", {
                        src: _imports_2,
                        style: {"padding":"0 0.3rem 0 0.3rem"}
                      }),
                      _createElementVNode("img", {
                        src: _imports_2,
                        style: {"padding":"0 0.3rem 0 0.3rem"}
                      }),
                      _createElementVNode("img", {
                        src: _imports_2,
                        style: {"padding":"0 0.3rem 0 0.3rem"}
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(item.content), 1)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_el_button, {
          type: "success",
          style: {"width":"28rem","height":"2.8rem"}
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode("Make Money From Home Now！")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[30] || (_cache[30] = _createElementVNode("div", {
        class: "flexColumnCenterCenter",
        style: {"color":"#003248","font-size":"2.4rem","font-weight":"bold","padding-top":"6rem"}
      }, " Discover Indeed Jobs To Move You Into A Rewarding Career! ", -1)),
      _cache[31] || (_cache[31] = _createElementVNode("div", {
        class: "flexColumnCenterCenter",
        style: {"color":"#003248","font-size":"1.4rem","padding-top":"1rem"}
      }, " Apply for job opportunities that offer part-time and full-time flexibility. Play a key role in our journey to business excellence. ", -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", {
          class: "class9",
          onMouseenter: _cache[6] || (_cache[6] = ($event: any) => (imgOneShow.value = false)),
          onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (imgOneShow.value = true))
        }, [
          (imgOneShow.value)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "class8",
                src: require('@/assets/discover1-63c5Km9w.webp'),
                fit: "fill"
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          (!imgOneShow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"class10\" data-v-61529aa4>Remote Online Promoter</div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Earn extra wages every day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Flexible hours: minimum 30 mins/day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Work anytime, anywhere.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Use any smart device.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>0 skill needed.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Must be between 24-60 years old</div></div>", 7)),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_el_button, {
                    type: "success",
                    style: {"margin-top":"3rem","width":"20rem","height":"3rem"},
                    onClick: onSubmit
                  }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode(" Apply Now ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ], 32),
        _createElementVNode("div", {
          class: "class9",
          onMouseenter: _cache[8] || (_cache[8] = ($event: any) => (imgTwoShow.value = false)),
          onMouseleave: _cache[9] || (_cache[9] = ($event: any) => (imgTwoShow.value = true))
        }, [
          (imgTwoShow.value)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "class8",
                src: require('@/assets/discover2-CqakWN4R.webp'),
                fit: "fill"
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          (!imgTwoShow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"class10\" data-v-61529aa4>Remote Sales</div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Earn extra wages every day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Flexible hours: minimum 50 mins/day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Work anytime, anywhere.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Use any smart device.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Must be between 24-60 years old</div></div>", 6)),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_el_button, {
                    type: "success",
                    style: {"margin-top":"3rem","width":"20rem","height":"3rem"},
                    onClick: onSubmit
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" Apply Now ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ], 32),
        _createElementVNode("div", {
          class: "class9",
          onMouseenter: _cache[10] || (_cache[10] = ($event: any) => (imgThreeShow.value = false)),
          onMouseleave: _cache[11] || (_cache[11] = ($event: any) => (imgThreeShow.value = true))
        }, [
          (imgThreeShow.value)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "class8",
                src: require('@/assets/discover3-mSOkZsUh.webp'),
                fit: "fill"
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          (!imgThreeShow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _cache[29] || (_cache[29] = _createStaticVNode("<div class=\"class10\" data-v-61529aa4>Remote Customer Service</div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Earn extra wages every day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Flexible hours: minimum 60 mins/day.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Work anytime, anywhere.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Use any smart device.</div></div><div class=\"flexRowCenter class11\" data-v-61529aa4><img src=\"" + _imports_3 + "\" data-v-61529aa4><div data-v-61529aa4>Must be between 24-60 years old</div></div>", 6)),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_el_button, {
                    type: "success",
                    style: {"margin-top":"3rem","width":"20rem","height":"3rem"},
                    onClick: onSubmit
                  }, {
                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createTextVNode(" Apply Now ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[36] || (_cache[36] = _createElementVNode("div", {
        class: "flexColumnCenterCenter",
        style: {"color":"#003248","font-size":"2.4rem","font-weight":"bold","padding-top":"3rem"}
      }, " How FunJobsBuilder is Different？ ", -1)),
      _cache[37] || (_cache[37] = _createElementVNode("div", {
        class: "flexColumnCenterCenter",
        style: {"color":"#003248","font-size":"1.4rem","padding-top":"1rem"}
      }, " Featuring 104,861 Online Jobs From 4,692 Companies ", -1)),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_el_image, {
              src: require('@/assets/legit.png'),
              fit: "fill",
              style: {"width":"1.5rem","height":"1.3rem","background-color":"#dcfefb","padding":"1rem","border-radius":"0.5rem"}
            }, null, 8, ["src"]),
            _cache[32] || (_cache[32] = _createElementVNode("div", { style: {"padding-left":"1rem","color":"#385576"} }, [
              _createElementVNode("div", { style: {"font-size":"2rem","font-weight":"bold"} }, " Legit Jobs Only "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " - Free from Ads, Scams, and Junk "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " Find real job opportunities. Our team spends more than 10 hours each day ensuring all remote jobs are verified and scam-free. ")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_el_image, {
              src: require('@/assets/unlock.png'),
              fit: "fill",
              style: {"width":"1.5rem","height":"1.3rem","background-color":"#dcfefb","padding":"1rem","border-radius":"0.5rem"}
            }, null, 8, ["src"]),
            _cache[33] || (_cache[33] = _createElementVNode("div", { style: {"padding-left":"1rem","color":"#385576"} }, [
              _createElementVNode("div", { style: {"font-size":"2rem","font-weight":"bold"} }, " Unlock Full Access "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " – Unlimited Job Search Resources "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " Enjoy full access to all our features: unlimited job listings, valuable articles, skills tests, and informative webinars to support your remote job search ")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_el_image, {
              src: require('@/assets/efficient.png'),
              fit: "fill",
              style: {"width":"1.5rem","height":"1.3rem","background-color":"#dcfefb","padding":"1rem","border-radius":"0.5rem"}
            }, null, 8, ["src"]),
            _cache[34] || (_cache[34] = _createElementVNode("div", { style: {"padding-left":"1rem","color":"#385576"} }, [
              _createElementVNode("div", { style: {"font-size":"2rem","font-weight":"bold"} }, " Efficient Job Hunting "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " – WhatsApp to Application Directly "),
              _createElementVNode("div", { style: {"margin-top":"1rem"} }, " Optimize your job search by messaging directly from WhatsApp to job applications, avoiding the use of multiple job boards. ")
            ], -1))
          ])
        ]),
        _createVNode(_component_el_image, {
          src: require('@/assets/different-XN7E96UD.webp'),
          fit: "fill",
          style: {"width":"32.1rem"}
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_el_button, {
          type: "success",
          style: {"width":"28rem","height":"2.8rem"}
        }, {
          default: _withCtx(() => _cache[35] || (_cache[35] = [
            _createTextVNode("Make Money From Home Now！")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _cache[47] || (_cache[47] = _createElementVNode("div", { style: {"font-size":"3rem","font-weight":"bold","margin-top":"5rem"} }, " Over 8 Million Job Seekers ", -1)),
      _cache[48] || (_cache[48] = _createElementVNode("div", { style: {"font-size":"1.5rem","font-weight":"bold","margin-top":"1rem"} }, " Have Used FunJobsBuilder to Find a Better Way to Make Money From Home ", -1)),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createVNode(_component_el_image, {
            src: require('@/assets/seekers1-CDDczeKG.png'),
            fit: "fill",
            style: {"width":"8rem","height":"8rem","border-radius":"50%"}
          }, null, 8, ["src"]),
          _cache[38] || (_cache[38] = _createElementVNode("div", { style: {"font-size":"1.5rem","font-weight":"bold","margin-top":"1rem"} }, " Legit At Home Jobs ", -1)),
          _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"font-size":"1rem","font-weight":"bold","margin-top":"1rem","color":"#999"} }, " Provide a range of legitimate at-home job opportunities that allow you to work from the comfort of your home. ", -1))
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_el_image, {
            src: require('@/assets/seekers2-fQqOLGA7.png'),
            fit: "fill",
            style: {"width":"8rem","height":"8rem","border-radius":"50%"}
          }, null, 8, ["src"]),
          _cache[40] || (_cache[40] = _createElementVNode("div", { style: {"font-size":"1.5rem","font-weight":"bold","margin-top":"1rem"} }, " Competitive Salary ", -1)),
          _cache[41] || (_cache[41] = _createElementVNode("div", { style: {"font-size":"1rem","font-weight":"bold","margin-top":"1rem","color":"#999"} }, " Performance-based pay guarantees extra pay every day ", -1))
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createVNode(_component_el_image, {
            src: require('@/assets/seekers3-BX76o7RI.png'),
            fit: "fill",
            style: {"width":"8rem","height":"8rem","border-radius":"50%"}
          }, null, 8, ["src"]),
          _cache[42] || (_cache[42] = _createElementVNode("div", { style: {"font-size":"1.5rem","font-weight":"bold","margin-top":"1rem"} }, " Flexible Working Hours ", -1)),
          _cache[43] || (_cache[43] = _createElementVNode("div", { style: {"font-size":"1rem","font-weight":"bold","margin-top":"1rem","color":"#999"} }, " Enjoy the freedom of flexible working hours, with a minimum requirement of just 30 minutes per day. ", -1))
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_el_image, {
            src: require('@/assets/seekers4-tH8-8NhH.png'),
            fit: "fill",
            style: {"width":"8rem","height":"8rem","border-radius":"50%"}
          }, null, 8, ["src"]),
          _cache[44] || (_cache[44] = _createElementVNode("div", { style: {"font-size":"1.5rem","font-weight":"bold","margin-top":"1rem"} }, " No Experience Needed ", -1)),
          _cache[45] || (_cache[45] = _createElementVNode("div", { style: {"font-size":"1rem","font-weight":"bold","margin-top":"1rem","color":"#999"} }, " Comprehensive training and support provided. Just bring your willingness to learn and a reliable internet connection. ", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_el_button, {
          type: "success",
          style: {"width":"28rem","height":"2.8rem"}
        }, {
          default: _withCtx(() => _cache[46] || (_cache[46] = [
            _createTextVNode("Find Indeed Jobs Near Me!")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_32, [
      _cache[57] || (_cache[57] = _createElementVNode("div", { style: {"font-size":"3rem","font-weight":"bold","margin-top":"8rem"} }, " FAQs ", -1)),
      _createVNode(_component_el_collapse, { style: {"width":"100rem","margin-top":"2rem"} }, {
        default: _withCtx(() => [
          _createVNode(_component_el_collapse_item, {
            name: "1",
            style: {"padding":"2rem 0 2rem 0"}
          }, {
            title: _withCtx(() => _cache[49] || (_cache[49] = [
              _createElementVNode("div", { style: {"font-size":"1.5rem"} }, " What Types of Tasks Are Available? ", -1)
            ])),
            default: _withCtx(() => [
              _cache[50] || (_cache[50] = _createElementVNode("div", { style: {"font-size":"1rem"} }, " We offer a diverse range of tasks such as market research, online shopping, and surveys, Our recruiters will conduct an online interview to provide tasks that suit you before starting! ", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_collapse_item, {
            name: "2",
            style: {"padding":"2rem 0 2rem 0"}
          }, {
            title: _withCtx(() => _cache[51] || (_cache[51] = [
              _createElementVNode("div", { style: {"font-size":"1.5rem"} }, "Can I choose my work hours？", -1)
            ])),
            default: _withCtx(() => [
              _cache[52] || (_cache[52] = _createElementVNode("div", { style: {"font-size":"1rem"} }, " Absolutely! You have the flexibility to choose your work hours, making it easy to fit tasks into your schedule. ", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_collapse_item, {
            name: "3",
            style: {"padding":"2rem 0 2rem 0"}
          }, {
            title: _withCtx(() => _cache[53] || (_cache[53] = [
              _createElementVNode("div", { style: {"font-size":"1.5rem"} }, "How will I be paid?", -1)
            ])),
            default: _withCtx(() => [
              _cache[54] || (_cache[54] = _createElementVNode("div", { style: {"font-size":"1rem"} }, " Payments are processed quickly and securely through your preferred method, such as direct deposit or PayPal. ", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_collapse_item, {
            name: "4",
            style: {"padding":"2rem 0 2rem 0"}
          }, {
            title: _withCtx(() => _cache[55] || (_cache[55] = [
              _createElementVNode("div", { style: {"font-size":"1.5rem"} }, " Are there any minimum requirements for the tasks? ", -1)
            ])),
            default: _withCtx(() => [
              _cache[56] || (_cache[56] = _createElementVNode("div", { style: {"font-size":"1rem"} }, " Requirements may vary depending on the task. Generally, you'll need a computer or mobile device with internet access and a basic understanding of English. Specific tasks might require additional skills or software. ", -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_33, [
      _createVNode(_component_el_image, {
        src: require('@/assets/earn1.png'),
        fit: "cover",
        style: {"width":"30rem","margin-top":"4rem"}
      }, null, 8, ["src"]),
      _createElementVNode("div", _hoisted_34, [
        _cache[59] || (_cache[59] = _createElementVNode("div", { style: {"font-size":"3rem","color":"#fff","font-weight":"bold"} }, " Earn tons of extra pay every week in your free time! ", -1)),
        _cache[60] || (_cache[60] = _createElementVNode("div", { style: {"font-size":"2rem","color":"#fff","margin-top":"1rem"} }, " Seize the jobs opportunity now！ ", -1)),
        _createVNode(_component_el_button, {
          type: "success",
          style: {"margin-top":"2rem","width":"10rem"},
          onClick: onSubmit
        }, {
          default: _withCtx(() => _cache[58] || (_cache[58] = [
            _createTextVNode(" Apply Now ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_35, [
      _createElementVNode("div", _hoisted_36, [
        _createVNode(_component_el_image, {
          src: require('@/assets/logo.png'),
          fit: "cover",
          style: {"width":"10rem"}
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_el_divider),
      _cache[61] || (_cache[61] = _createElementVNode("div", {
        class: "flexRowCenterCenter",
        style: {"font-size":"1rem","padding":"2.5rem 0 2.5rem 0"}
      }, " Copyrights © 2024 www.funjobsbuilder.com Rights Reserved. ", -1))
    ])
  ]))
}
}

})